import axios from "axios"
import { useEffect, useState } from "react"
import { Cell, Description, HeadCell, HeadRow, Header, LeaderboardContent, LeaderboardWrapper, Row, Table } from "./styles"

interface LeaderboardItem {
    matches: number
    winrate: number
	kills: number
	deaths: number
	damage: number
	position: number
	nickname: string
	wins: number
	userId: number
}

interface ILeaderboard {
	leaderboard: LeaderboardItem[]
	user: LeaderboardItem | null
}

export const Leaderboard = () => {
	const [leaderboard, setLeaderboard] = useState<LeaderboardItem[]>([])
	useEffect(() => {
		const requestData = async () => {
			try {
				const res = await axios.get<ILeaderboard>(process.env.REACT_APP_LEADERBOARD_ENDPOINT!)
				setLeaderboard(res.data.leaderboard)
			} catch (error) {}
		}
		requestData()
	}, [])
	if (!leaderboard.length) return <></>
	return (
		<LeaderboardWrapper>
			<LeaderboardContent>
				<Header>LEADERBOARD</Header>
				<Table>
					<HeadRow>
						<HeadCell>Place</HeadCell>
						<HeadCell>Name</HeadCell>
						<HeadCell>Wins</HeadCell>
						<HeadCell>Matches</HeadCell>
						<HeadCell>Kills</HeadCell>
						<HeadCell>Win Rate*</HeadCell>
					</HeadRow>

					{leaderboard.map((record, index) => (
						<Row>
							<Cell title={String(record.position)}>{record.position}</Cell>
							<Cell title={String(record.nickname)}>{record.nickname}</Cell>
							<Cell title={String(record.wins)}>{record.wins}</Cell>
							<Cell title={String(record.matches)}>{record.matches}</Cell>
							<Cell title={String(record.kills)}>{record.kills}</Cell>
							<Cell title={String(record.winrate)}>{record.winrate}</Cell>
						</Row>
					))}
					<Description>*Unlocked after 50 matches, but recorded from game 1</Description>
				</Table>
			</LeaderboardContent>
		</LeaderboardWrapper>
	)
}
