import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "./nullable.css"
import { Leaderboard } from "./components/Leaderboard"
import { BrowserRouter as Router } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
	<React.StrictMode>
		<Router basename="/leaderboard">
			<Leaderboard />
		</Router>
	</React.StrictMode>
)
