import styled from "styled-components"

export const LeaderboardWrapper = styled.div`
	height: 100%;
	width: 100%;

    display: grid;
    place-items: center;

	* {
		font-family: "Red Hat Display";
		color: white;
	}
`
export const LeaderboardContent = styled.div`
	height: 100%;
	width: 100%;

    max-width: 760px;
    min-width: 520px;
	display: grid;
	place-items: center;
	grid-template-rows: 210px 1fr;
`
export const Table = styled.div`
    width: 100%;
	position: relative;
	background-color: rgba(9, 116, 239, 0.06);
	padding: 30px 30px 10px;
	border-radius: 13px;
	align-self: start;

	/* max-height: 70dvh;
	overflow-y: scroll; */
`
export const Header = styled.div`
	text-transform: uppercase;
	font-weight: 700;
	font-size: 52px;

	margin: 40px 0;
`
const CommonRow = styled.div`
	display: grid;
	grid-template-columns: 15% 25% 15% 15% 15% 15%;
	/* column-gap: 60px; */
    max-width: 100%;
	& div:nth-child(n + 3) {
		text-align: right;
	}
`
export const HeadRow = styled(CommonRow)`
	padding: 0 0 20px;
`
export const HeadCell = styled.div`
	text-transform: uppercase;
	color: rgba(9, 99, 239, 1);
	font-weight: 700;
	font-size: 13px;
`
export const Row = styled(CommonRow)`
	border-top: 1px solid rgba(169, 177, 189, 0.3);
	padding: 15px 0;
`
export const Cell = styled.div`
	font-size: 14px;
	font-weight: 400;
`
export const Description = styled.div`
	position: absolute;

    bottom: -30px;
	left: 30px;

	font-size: 14px;
	font-weight: 300;
`
